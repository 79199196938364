<template>
    <div>
      <table>
        <tr v-if="!dataDetail">
          <td colspan='4' style="text-align-last: center;"><strong>No hay datos</strong></td>
        </tr>
        <tr v-if="dataDetail">
          <th></th>
          <th>Valor Anterior</th>
          <th>Valor Nuevo</th>
          <th>Fecha Asignación</th>
        </tr>
        <tr v-for="(item, index) in dataDetail" :key="index" >
          <td v-if="index == 'row'">Roles</td>
          <td v-if="index == 'row'">{{ item.lastRoleList }}</td>
          <td v-if="index == 'row'">{{ item.newRoleList }}</td>
          <td v-if="index == 'row'">{{ item.createdAt }}</td>
        </tr>
      </table>
    </div>
  </template>
  
  <script>
  export default {
    name: "ARDetail",
    props: {
      dataDetail: {
        type: Object
      },
    }
  }
  </script>